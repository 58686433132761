<template>
  <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
    <div
      class="card-header"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ $t("label.Movements") }}</span>
    </div>
    <div class="card-body">
      <CRow>
        <CCol sm="12" class="center-all">
          <div class="movs-container">
            <div class="movs-title">
              <div class="movs-highlight bg-planned">
                <CIcon name="cil-description" class="mr-2"/>
                <span>{{$t('label.planned')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle text-mov-full">
                {{ $t('label.full') }}
              </div>
              <div class="movs-subtitle text-mov-empty">
                {{ $t('label.empty') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-full" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('planned', 'full')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalPlannedFull"
                ></RadialBar>
              </div>
              <div class="graph-empty" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('planned', 'empty')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalPlannedEmpty"
                ></RadialBar>
              </div>
            </div>
          </div>

          <div class="movs-container">
            <div class="movs-title">
              <div class="movs-highlight bg-exec">
                <CIcon name="cil-task" class="mr-2"/>
                <span>{{$t('label.executed')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle text-mov-full">
                {{ $t('label.full') }}
              </div>
              <div class="movs-subtitle text-mov-empty">
                {{ $t('label.empty') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-full" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('exec', 'full')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalExecFull"
                ></RadialBar>
              </div>
              <div class="graph-empty" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('exec', 'empty')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalExecEmpty"
                ></RadialBar>
              </div>
            </div>
          </div>

          <div class="movs-container">
            <div class="movs-title">
              <div class="movs-highlight bg-today">
                <CIcon name="cil-calendar" class="mr-2"/>
                <span>{{$t('label.today')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle text-mov-full">
                {{ $t('label.full') }}
              </div>
              <div class="movs-subtitle text-mov-empty">
                {{ $t('label.empty') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-full" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('today', 'full')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalTodayFull"
                ></RadialBar>
              </div>
              <div class="graph-empty" v-if="bandera">
                <RadialBar
                  height="180"
                  :series="getDataByType('today', 'empty')"
                  :colors="colors"
                  :labels="labels"
                  :total="getTotalTodayEmpty"
                ></RadialBar>
              </div>
            </div>
          </div>
        </CCol>
        <!-- CONTAITER SHIP -->
        <CCol sm="12" class="mb-2">
          <div class="movs-info">
            <div class="movs-labels">
              <div class="movs-icon bg-descarga mr-2">
                <CIcon name="cil-arrow-bottom"/>
              </div>
              <span>{{$t('label.DISCHARGE')}}</span>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getPlannedValues('full', 'descarga') }}
              </div>
              <div class="movs-value text-center">
                {{ getPlannedValues('empty', 'descarga') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getExecValues('full', 'descarga') }}
              </div>
              <div class="movs-value text-center">
                {{ getExecValues('empty', 'descarga') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getTodayValues('full', 'descarga') }}
              </div>
              <div class="movs-value text-center">
                {{ getTodayValues('empty', 'descarga') }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="descarga-operativa" :value="getTotalDescarga">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotalDescarga}%` }}</span>
            </div>
          </div>
          <div class="movs-info mt-1">
            <div class="movs-labels">
              <div class="movs-icon bg-carga mr-2">
                <CIcon name="cil-arrow-top"/>
              </div>
              <span>{{$t('label.LOAD')}}</span>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getPlannedValues('full', 'carga') }}
              </div>
              <div class="movs-value text-center">
                {{ getPlannedValues('empty', 'carga') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getExecValues('full', 'carga') }}
              </div>
              <div class="movs-value text-center">
                {{ getExecValues('empty', 'carga') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getTodayValues('full', 'carga') }}
              </div>
              <div class="movs-value text-center">
                {{ getTodayValues('empty', 'carga') }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="dcarga-operativa" :value="getTotalCarga">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotalCarga}%` }}</span>
            </div>
          </div>
          <!-- Restow -->
          <div class="movs-info mt-1">
            <div class="movs-labels">
              <div class="movs-icon bg-ship-ship mr-2">
                <CIcon name="cil-transfer"/>
              </div>
              <span>{{$t('label.shipToShip')}}</span>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getPlannedValues('full', 'shipShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getPlannedValues('empty', 'shipShip') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getExecValues('full', 'shipShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getExecValues('empty', 'shipShip') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getTodayValues('full', 'shipShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getTodayValues('empty', 'shipShip') }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="shipship-operativa" :value="getTotalShipShip">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotalShipShip}%` }}</span>
            </div>
          </div>
          <div class="movs-info mt-1">
            <div class="movs-labels">
              <div class="movs-icon bg-restow mr-2">
                <CIcon name="cil-transfer"/>
              </div>
              <span>{{$t('label.shipLandShip')}}</span>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getPlannedValues('full', 'shipLandShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getPlannedValues('empty', 'shipLandShip') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getExecValues('full', 'shipLandShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getExecValues('empty', 'shipLandShip') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getTodayValues('full', 'shipLandShip') }}
              </div>
              <div class="movs-value text-center">
                {{ getTodayValues('empty', 'shipLandShip') }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="restow-operativa" :value="getTotalShipLandShip">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotalShipLandShip}%` }}</span>
            </div>
          </div>
          <!-- End Restow -->
          <div class="movs-info mt-1">
            <div class="movs-labels">
              <div class="movs-icon bg-cancelado mr-2">
                <CIcon name="cil-x"/>
              </div>
              <span>{{$t('label.CANCELADO')}}</span>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getPlannedValues('full', 'cancel') }}
              </div>
              <div class="movs-value text-center">
                {{ getPlannedValues('empty', 'cancel') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getExecValues('full', 'cancel') }}
              </div>
              <div class="movs-value text-center">
                {{ getExecValues('empty', 'cancel') }}
              </div>
            </div>
            <div class="movs-container movs-direction">
              <div class="movs-value text-center">
                {{ getTodayValues('full', 'cancel') }}
              </div>
              <div class="movs-value text-center">
                {{ getTodayValues('empty', 'cancel') }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="cancelado-operativa" :value="getTotalCancel">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotalCancel}%` }}</span>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import RadialBar from "../../components/radialBar";

//Data
function data() {
  return {
    colors: ['#e1373f', '#007A7B', '#97CCAB', '#EC631B', '#1A3760'],
  }
}

// Methods
function getDataByType(mov, type) {
  if(!this.movimientos)  return [0, 0, 0, 0, 0];

  switch (mov) {
    case 'planned':
      if(type == 'full') {
        let total = this.getTotalPlannedFull;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Cancel[0].Full, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].ShipShip[0].Full, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Full, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Discharge[0].Full, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Load[0].Full, total),
        ];
      } else {
        let total = this.getTotalPlannedEmpty;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Cancel[0].Empty, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].ShipShip[0].Empty, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Empty, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Discharge[0].Empty, total),
          this.calcPercent(this.movimientos.PlannedMovsJson[0].Load[0].Empty, total),
        ];
      }
    case 'exec':
      if(type == 'full') {
        let total = this.getTotalExecFull;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Cancel[0].Full, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].ShipShip[0].Full, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Full, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Discharge[0].Full, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Load[0].Full, total),
        ];
      } else {
        let total = this.getTotalExecEmpty;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Cancel[0].Empty, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].ShipShip[0].Empty, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Empty, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Discharge[0].Empty, total),
          this.calcPercent(this.movimientos.ExecutedMovsJson[0].Load[0].Empty, total),
        ];
      }
    case 'today':
      if(type == 'full') {
        let total = this.getTotalTodayFull;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.TodayMovsJson[0].Cancel[0].Full, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].ShipShip[0].Full, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].ShipLandShip[0].Full, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].Discharge[0].Full, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].Load[0].Full, total),
        ];
      } else {
        let total = this.getTotalPlannedEmpty;
        if(Object.keys(this.movimientos).length === 0) return [];
        return [
          this.calcPercent(this.movimientos.TodayMovsJson[0].Cancel[0].Empty, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].ShipShip[0].Empty, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].ShipLandShip[0].Empty, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].Discharge[0].Empty, total),
          this.calcPercent(this.movimientos.TodayMovsJson[0].Load[0].Empty, total),
        ];
      }
    default:
      return [0, 0, 0, 0, 0];
  }
}

function getPlannedValues(type, value) {
  if(!this.movimientos) return 'N/A';
  if(Object.keys(this.movimientos).length === 0) return 'N/A';
  if(type == 'full'){
    switch (value) {
      case 'carga':
        return this.movimientos.PlannedMovsJson[0].Load[0].Full;
      case 'descarga':
        return this.movimientos.PlannedMovsJson[0].Discharge[0].Full;
      case 'shipShip':
        return this.movimientos.PlannedMovsJson[0].ShipShip[0].Full;
      case 'shipLandShip':
        return this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Full;
      case 'cancel':
        return this.movimientos.PlannedMovsJson[0].Cancel[0].Full;
      default:
        return 'N/A';
    }
  }else {
    switch (value) {
      case 'carga':
        return this.movimientos.PlannedMovsJson[0].Load[0].Empty;
      case 'descarga':
        return this.movimientos.PlannedMovsJson[0].Discharge[0].Empty;
      case 'shipShip':
        return this.movimientos.PlannedMovsJson[0].ShipShip[0].Empty;
      case 'shipLandShip':
        return this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Empty;
      case 'cancel':
        return this.movimientos.PlannedMovsJson[0].Cancel[0].Empty;
      default:
        return 'N/A';
    }
  }
}
function getExecValues(type, value) {
  if(!this.movimientos) return 'N/A';
  if(Object.keys(this.movimientos).length === 0) return 'N/A';
  if(type == 'full'){
    switch (value) {
      case 'carga':
        return this.movimientos.ExecutedMovsJson[0].Load[0].Full;
      case 'descarga':
        return this.movimientos.ExecutedMovsJson[0].Discharge[0].Full;
      case 'shipShip':
        return this.movimientos.ExecutedMovsJson[0].ShipShip[0].Full;
      case 'shipLandShip':
        return this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Full;
      case 'cancel':
        return this.movimientos.ExecutedMovsJson[0].Cancel[0].Full;
      default:
        return 'N/A';
    }
  }else {
    switch (value) {
      case 'carga':
        return this.movimientos.ExecutedMovsJson[0].Load[0].Empty;
      case 'descarga':
        return this.movimientos.ExecutedMovsJson[0].Discharge[0].Empty;
      case 'shipShip':
        return this.movimientos.ExecutedMovsJson[0].ShipShip[0].Empty;
      case 'shipLandShip':
        return this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Empty;
      case 'cancel':
        return this.movimientos.ExecutedMovsJson[0].Cancel[0].Empty;
      default:
        return 'N/A';
    }
  }
}
function getTodayValues(type, value) {
  if(!this.movimientos) return 'N/A';
  if(Object.keys(this.movimientos).length === 0) return 'N/A';
  if(type == 'full'){
    switch (value) {
      case 'carga':
        return this.movimientos.TodayMovsJson[0].Load[0].Full;
      case 'descarga':
        return this.movimientos.TodayMovsJson[0].Discharge[0].Full;
      case 'shipShip':
        return this.movimientos.TodayMovsJson[0].ShipShip[0].Full;
      case 'shipLandShip':
        return this.movimientos.TodayMovsJson[0].ShipLandShip[0].Full;
      case 'cancel':
        return this.movimientos.TodayMovsJson[0].Cancel[0].Full;
      default:
        return 'N/A';
    }
  }else {
    switch (value) {
      case 'carga':
        return this.movimientos.TodayMovsJson[0].Load[0].Empty;
      case 'descarga':
        return this.movimientos.TodayMovsJson[0].Discharge[0].Empty;
      case 'shipShip':
        return this.movimientos.TodayMovsJson[0].ShipShip[0].Empty;
      case 'shipLandShip':
        return this.movimientos.TodayMovsJson[0].ShipLandShip[0].Empty;
      case 'cancel':
        return this.movimientos.TodayMovsJson[0].Cancel[0].Empty;
      default:
        return 'N/A';
    }
  }
}
function calcPercent(value, total) {
  if(value == 0 || total == 0) return 0;

  let percent = (value/total) * 100;
  return Math.round(percent * 1e2) / 1e2;
}

//computed
function bandera(){
  return this.$store.state.situacionOperativaActual.tabsOperationes === 1 && this.$store.state.situacionOperativaActual.carruselTab === 3; 
}
function labels() {
  return [
    this.$t('label.CANCELADO'),
    this.$t('label.shipToShip'),
    this.$t('label.shipLandShip'),
    this.$t('label.DISCHARGE'),
    this.$t('label.LOAD'),
  ];
}
function getTotalPlannedFull() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.PlannedMovsJson[0].Cancel[0].Full + this.movimientos.PlannedMovsJson[0].ShipShip[0].Full + this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Full + this.movimientos.PlannedMovsJson[0].Discharge[0].Full + this.movimientos.PlannedMovsJson[0].Load[0].Full;
}
function getTotalPlannedEmpty() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.PlannedMovsJson[0].Cancel[0].Empty + this.movimientos.PlannedMovsJson[0].ShipShip[0].Empty + this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Empty + this.movimientos.PlannedMovsJson[0].Discharge[0].Empty + this.movimientos.PlannedMovsJson[0].Load[0].Empty;
}
function getTotalExecEmpty() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.ExecutedMovsJson[0].Cancel[0].Empty + this.movimientos.ExecutedMovsJson[0].ShipShip[0].Empty + this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Empty + this.movimientos.ExecutedMovsJson[0].Discharge[0].Empty + this.movimientos.ExecutedMovsJson[0].Load[0].Empty;
}
function getTotalExecFull() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.ExecutedMovsJson[0].Cancel[0].Full + this.movimientos.ExecutedMovsJson[0].ShipShip[0].Full + this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Full + this.movimientos.ExecutedMovsJson[0].Discharge[0].Full + this.movimientos.ExecutedMovsJson[0].Load[0].Full;
}
function getTotalTodayEmpty() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.TodayMovsJson[0].Cancel[0].Empty + this.movimientos.TodayMovsJson[0].ShipShip[0].Empty + this.movimientos.TodayMovsJson[0].ShipLandShip[0].Empty + this.movimientos.TodayMovsJson[0].Discharge[0].Empty + this.movimientos.TodayMovsJson[0].Load[0].Empty;
}
function getTotalTodayFull() {
    if(!this.movimientos)  return 0;
    if(Object.keys(this.movimientos).length === 0) return 0;
    return this.movimientos.TodayMovsJson[0].Cancel[0].Full + this.movimientos.TodayMovsJson[0].ShipShip[0].Full + this.movimientos.TodayMovsJson[0].ShipLandShip[0].Full + this.movimientos.TodayMovsJson[0].Discharge[0].Full + this.movimientos.TodayMovsJson[0].Load[0].Full;
}

function getTotalDescarga() {
  if(!this.movimientos)  return 0;
  if(Object.keys(this.movimientos).length === 0) return 0;
  let totales = this.movimientos.PlannedMovsJson[0].Discharge[0].Full + this.movimientos.PlannedMovsJson[0].Discharge[0].Empty;
  let exce = this.movimientos.ExecutedMovsJson[0].Discharge[0].Full + this.movimientos.ExecutedMovsJson[0].Discharge[0].Empty;

  if(exce == 0) return 0;
  if(totales == 0 && exce != 0) return 100;

  return this.calcPercent(exce, totales);
}
function getTotalCarga() {
  if(!this.movimientos)  return 0;
  if(Object.keys(this.movimientos).length === 0) return 0;
  let totales = this.movimientos.PlannedMovsJson[0].Load[0].Full + this.movimientos.PlannedMovsJson[0].Load[0].Empty;
  let exce = this.movimientos.ExecutedMovsJson[0].Load[0].Full + this.movimientos.ExecutedMovsJson[0].Load[0].Empty;

  if(exce == 0) return 0;
  if(totales == 0 && exce != 0) return 100;
  
  return this.calcPercent(exce, totales);
}

function getTotalShipShip() {
  if(!this.movimientos)  return 0;
  if(Object.keys(this.movimientos).length === 0) return 0;
  let totales = this.movimientos.PlannedMovsJson[0].ShipShip[0].Full + this.movimientos.PlannedMovsJson[0].ShipShip[0].Empty;
  let exce = this.movimientos.ExecutedMovsJson[0].ShipShip[0].Full + this.movimientos.ExecutedMovsJson[0].ShipShip[0].Empty;

  if(exce == 0) return 0;
  if(totales == 0 && exce != 0) return 100;
  if(totales > 0 && exce != 0 && totales < exce) return 100;

  return this.calcPercent(exce, totales);
}
function getTotalShipLandShip() {
  if(!this.movimientos)  return 0;
  if(Object.keys(this.movimientos).length === 0) return 0;
  let totales = this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Full + this.movimientos.PlannedMovsJson[0].ShipLandShip[0].Empty;
  let exce = this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Full + this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Empty;

  if(exce == 0) return 0;
  if(totales == 0 && exce != 0) return 100;
  if(totales > 0 && exce != 0 && totales < exce) return 100;

  return this.calcPercent(exce, totales);
}
function getTotalCancel() {
  if(!this.movimientos)  return 0;
  if(Object.keys(this.movimientos).length === 0) return 0;
  let totales = this.movimientos.ExecutedMovsJson[0].Discharge[0].Full + this.movimientos.ExecutedMovsJson[0].Discharge[0].Empty +
    this.movimientos.ExecutedMovsJson[0].Load[0].Full + this.movimientos.ExecutedMovsJson[0].Load[0].Empty +
    this.movimientos.ExecutedMovsJson[0].ShipShip[0].Full + this.movimientos.ExecutedMovsJson[0].ShipShip[0].Empty +
    this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Full + this.movimientos.ExecutedMovsJson[0].ShipLandShip[0].Empty;
  let exce = this.movimientos.ExecutedMovsJson[0].Cancel[0].Full + this.movimientos.ExecutedMovsJson[0].Cancel[0].Empty;

  if(exce == 0 || totales == 0) return 0;
  if(totales > 0 && exce > 0 && totales == exce) return 100;

  return this.calcPercent(exce, totales);
}

export default {
  name: "movimientos",
  props: {
    movimientos: {
      type: Object,
      required: true,
      default: null,
    },
  },
  components: {
    RadialBar,
  },
  data,
  methods: {
    getDataByType,
    getPlannedValues,
    getExecValues,
    getTodayValues,
    calcPercent,
  },
  computed: {
    getTotalTodayEmpty,
    getTotalTodayFull,
    getTotalPlannedFull,
    getTotalPlannedEmpty,
    getTotalExecEmpty,
    getTotalExecFull,
    getTotalDescarga,
    getTotalCarga,
    getTotalCancel,
    labels,
    bandera,
    getTotalShipShip,
    getTotalShipLandShip,
  },
};
</script>

<style scoped>
.center-all {
  display: flex;
  justify-content: center;
}
.movs-container {
  width: calc(calc(100% / 4));

  display: flex;
  flex-direction: column;

  /* border: 1px solid #000; */
}

.movs-title {
  width: 100%;
  display: flex;
  justify-content: center;
}
.movs-subtitle {
  width: calc(100% / 2);
  text-align: center;
}
.movs-highlight {
  width: 80%;
  border-radius: 20px;
  background-color:#000;
  color: #fff;
  text-align: center;
  padding: 4px 0;
}

.graph {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.graph-full,
.graph-empty {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.movs-labels,
.movs-percent {
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.movs-info {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.movs-direction {
  flex-direction: row;
}
.movs-value {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.movs-icon {
  border-radius: 50%;
  padding: 10px 12px;
  color: #fff;
}

.movs-progress {
  width: 80%;
}

.bg-descarga {
  background-color: #EC631B;
}
.bg-carga {
  background-color: #1A3760;
}
.bg-restow {
  background-color: #97CCAB;
}
.bg-ship-ship {
  background-color: #007A7B;
}
.bg-cancelado {
  background-color: #e1373f;
}

.bg-planned {
  background-color: #828c9c;
}
.bg-exec {
  background-color: #ec631b;
}
.bg-today {
  background-color: #1e3a61;
}
.text-mov-full {
  color: #e13a42;
  font-weight: bold;
}
.text-mov-empty {
  color: #42aa91;
  font-weight: bold;
}
</style>